import React from 'react'
import { Authenticator, SignIn, Greetings } from 'aws-amplify-react';

const AlwaysOn = (props: any) => {
    return (
        <div>
            <div>I am always here to show current auth state: {props.authState}</div>
            <button onClick={() => props.onStateChange('signUp')}>Show Sign Up</button>
        </div>
    )
}

const handleAuthStateChange: any = (state: any) => {
    if (state === 'signedIn') {
        /* Do something when the user has signed-in */
    }
}

export const Home: React.FC = () => (
    <>
        <h2>Home</h2>
        <Authenticator hideDefault={true} onStateChange={handleAuthStateChange}>
            <SignIn/>
            <Greetings/>
            <AlwaysOn/>
        </Authenticator>
    </>
)
